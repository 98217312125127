<template>
  <CRow class="screens">
    <CCol cols="12" lg="12" md="12" class="pt-0" v-bind:class="{'pb-0' : screens.length <= perPage}">
      <CRow class="m-0 actions">
        <CCol cols="12" xl="12" class="pt-0 pb-0">
          <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
            <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getScreens()">{{$t('common.show_inactive')}}</b-switch>
            <div v-if="checkPermission('connect.signage.addeditscreen')" class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="ml-2 mr-0 link" color="primary" @click="openSidebarRight('signage_screen', { signage_screen_id_external: null, duplicate_screen: false })">
                <i class="fas fa-plus mr-1"/>
                <span>{{ $t('signage.Add_screen') }}</span>
              </CButton>
            </div>             
          </div>
        </CCol>
      </CRow>
      <hr class="m-0"/>
      <CRow>
        <CCol cols="12" lg="12" class="pb-0">
          <b-table ref="screenTable" class="data_table includes_dropdown" :data="screens" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
            <template slot-scope="props">
              <b-table-column field="screen_title" :label="$t('signage.Screen_title')" :searchable="searchEnabled">
                <span @click="openSidebarRight('signage_screen', { signage_screen_id_external: props.row.signage_screen_id_external, duplicate_screen: true })">{{props.row.screen_title}}</span>
                <div class="item_tags d-flex flex-row">
                  <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                </div>
              </b-table-column>
              <b-table-column field="screen_description" :label="$t('signage.Screen_description')" :searchable="searchEnabled" width="70%">
                <span @click="openSidebarRight('signage_screen', { signage_screen_id_external: props.row.signage_screen_id_external, duplicate_screen: true })">{{props.row.screen_description ? props.row.screen_description : '-'}}</span>
              </b-table-column>
              <!-- <b-table-column field="pairing_code" :label="$t('signage.Pairing_code')">
                <span>{{props.row.pairing_code}}</span>
              </b-table-column> -->
              <b-table-column field="actions">
                <div class="d-flex justify-content-lg-end">
                  <Caster :screenData="props.row"/>
                  <CDropdown color="primary" toggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                    <div slot="toggler-content">
                      <span class="d-flex align-items-center">
                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                      </span>
                    </div>

                    <CDropdownItem v-if="checkPermission('connect.signage.addeditscreen')">
                      <div class="d-flex align-items-center" @click="showScreen(props.row.signage_screen_id_external)">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-chevron-right"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('common.Show_details')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                    <CDropdownItem v-if="checkPermission('connect.signage.addeditscreen')">
                      <div class="d-flex align-items-center" @click="openSidebarRight('signage_screen', { signage_screen_id_external: props.row.signage_screen_id_external, duplicate_screen: true })">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-clone"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('signage.Clone_screen')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                    <CDropdownItem>
                      <div class="d-flex align-items-center" @click="openScreen(props.row)">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-external-link-alt"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('signage.Open_screen')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>                      
                  </CDropdown>
                </div>
              </b-table-column>
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('signage.Loading_screens')"/>
                <span v-else>{{ $t('signage.No_screens_found') }}</span>
              </div>                
            </template>                
          </b-table>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import Caster from '@/views/connect/signage/caster/Caster.vue';

export default {
  name: 'Screens',
  props: ['platformPermissions'],
  components: {
    loadingSpinner,
    Caster
  },
  data() {
    return {
      companyIdExternal: null,
      screens: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      showInactive: false
    }
  },
  methods: {   
    getScreens () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/screens')
      .then(res => {      
        this.screens = res.data.data;
        // Filter the data if necessary
        if(!this.showInactive) this.screens = this.screens.filter(i => ['Y'].includes( i.active ));
        // Check if the pagination should be activated
        (this.screens.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false; 
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;                 
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    updateScreen(id, data) {
      let screenIdExternal = id;
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      if(params.screen_description === '') params.screen_description = null;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/' + screenIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('signage.Screen_updated'), type: 'is-success', duration: 2000 });
        // Get the screen details
        this.getScreenDetails(screenIdExternal);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })         
    },
    getScreenDetails(id) {
      let screenIdExternal = id;

      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/' + screenIdExternal)
      .then(res => {
        let updatedScreen = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.screenTable !== undefined ) {
          // Get the screen index
          let screenIndex = this.$refs.screenTable.data.findIndex(x => x.signage_screen_id_external == updatedScreen.signage_screen_id_external);
          // Update the is active value for the row
          this.$refs.screenTable.data[screenIndex].is_active = updatedScreen.is_active;
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showScreen (id) {
      this.$router.push({ path: `signage/screens/${id.toString()}` });
    },
    openScreen(data) {
      var url = process.env.VUE_APP_SIGNAGE_URL + '/#/signage/live?company=' + this.companyIdExternal + '&screen=' + data.signage_screen_id_external + '&code=' + data.pairing_code;
      var win = window.open(url, '_blank'); 
      win.focus();
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  mounted: function(){
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    this.getScreens();
  
    this.$bus.$on('update_signage_screens', () => {
      this.getScreens();
    });
  },
  beforeDestroy() {
    this.$bus.$off('update_signage_screens');
  }
}
</script>